import { CSpinner } from '@coreui/react'
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { bearerToken } from './AuthProvider'
import './scss/style.scss'

const loading = (
  <div className="pt-3 text-center">
    <CSpinner color="primary" />
    <CSpinner color="secondary" />
    <CSpinner color="success" />
    <CSpinner color="danger" />
    <CSpinner color="warning" />
    <CSpinner color="info" />
    <CSpinner color="light" />
    <CSpinner color="dark" />
  </div>
)

// Containers
const AuthLayout = React.lazy(() => import('./layout/AuthLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const isLogged = useRecoilValue(bearerToken)
  const authToken = sessionStorage.getItem('authKey')
  const setToken = useSetRecoilState(bearerToken)
  useEffect(() => {
    if (authToken) {
      setToken(authToken)
    }
  }, [authToken, setToken])
  return (
    <Router>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route
            exact
            path="/"
            name="Login Page"
            render={(props) => <Login {...props} />}
          />
          <Route
            exact
            path="/404"
            name="Page 404"
            render={(props) => <Page404 {...props} />}
          />
          <Route
            exact
            path="/500"
            name="Page 500"
            render={(props) => <Page500 {...props} />}
          />
          {isLogged && (
            <Route
              path="/"
              name="Home"
              render={(props) => <AuthLayout {...props} />}
            />
          )}
          <Route component={Page404} />
        </Switch>
      </React.Suspense>
    </Router>
  )
}

export default App
